import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

import App from "./App";
import { AppStyles } from "./App.styled";
import "simplebar-react/dist/simplebar.min.css";
import "api/apiConfig";

polyfillCountryFlagEmojis();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <BrowserRouter>
      <App />
      <AppStyles />
    </BrowserRouter>
  </LocalizationProvider>
);
