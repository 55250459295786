import { FC, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SquereButton } from "components/Buttons";
import { AppText } from "components";
import { UserStore } from "stores";
import routesPaths from "routes/routesPaths";
import { colors, supportUrl } from "helpers/consts";
import { token } from "api/user";
import {
  UserMenuContainer,
  StyledMenu,
  StyledMenuItem,
  UserInfo,
  MenuDivider,
  // Image,
} from "../styled";

// import foxImage from "assets/images/metamask.png";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as DocumentsIcon } from "assets/icons/documents.svg";
import { ReactComponent as ZapIcon } from "assets/icons/zap.svg";
// import { ReactComponent as LayersIcon } from "assets/icons/layers-two.svg";
// import { ReactComponent as UserPlusIcon } from "assets/icons/user-plus.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/log-out.svg";
// import { ReactComponent as MetaMaskIcon } from "assets/icons/metamask.svg";
// import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as SignInIcon } from "assets/icons/signIn.svg";
import { ReactComponent as SignUpIcon } from "assets/icons/signUp.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";

interface UserMenuProps {
  isInSidebar?: boolean;
}
const UserMenu: FC<UserMenuProps> = ({ isInSidebar }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    user: { email },
    isAuthenticated,
    requestedForms,
  } = UserStore;
  const [isProfileMenuOpen, setIsProfileMenuOpen] =
    useState<null | HTMLElement>(null);
  // const [isMetaMaskOpen, setIsMetaMaskOpen] = useState<null | HTMLElement>(
  //   null
  // );
  const handleOpenProfileMenu = (e: MouseEvent<HTMLElement>) => {
    setIsProfileMenuOpen(e.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setIsProfileMenuOpen(null);
  };
  // const handleOpenMetaMusk = (e: MouseEvent<HTMLElement>) => {
  //   setIsMetaMaskOpen(e.currentTarget);
  // };
  // const handleCloseMetaMusk = () => {
  //   setIsMetaMaskOpen(null);
  // };
  const navigateToProfile = (url: string) => {
    navigate(url);
    handleCloseProfileMenu();
  };
  const navigateToAuth = (path: string) => {
    navigate(path);
    handleCloseProfileMenu();
  };
  const fullLogOut = () => {
    token.unset();
    window.location.reload();
  };

  const sendEmail = () => {
    window.location.assign(`mailto:${supportUrl}`);
  };

  return (
    <UserMenuContainer $isInSidebar={isInSidebar}>
      <SquereButton
        onClick={handleOpenProfileMenu}
        aria-controls={isProfileMenuOpen ? t("PROFILE_MENU") : undefined}
        aria-haspopup="true"
        aria-expanded={isProfileMenuOpen ? "true" : undefined}
      >
        <Badge
          badgeContent={requestedForms?.length || 0}
          invisible={!requestedForms || requestedForms.length === 0}
        >
          <UserIcon />
        </Badge>
      </SquereButton>

      {/* <SquereButton
        onClick={handleOpenMetaMusk}
        aria-controls={isMetaMaskOpen ? t("METAMASK_MENU") : undefined}
        aria-haspopup="true"
        aria-expanded={isMetaMaskOpen ? "true" : undefined}
      >
        <Image src={foxImage} />
      </SquereButton> */}

      {isAuthenticated ? (
        <StyledMenu
          anchorEl={isProfileMenuOpen}
          open={!!isProfileMenuOpen}
          onClose={handleCloseProfileMenu}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <UserInfo tabIndex={-1}>
            {/* {lastName && (
              <AppText noWrap color={colors.gray_700} fontWeight={600}>
                {lastName}
              </AppText>
            )} */}
            <AppText noWrap color={colors.gray_700} fontWeight={600}>
              {email}
            </AppText>
          </UserInfo>
          <MenuDivider />
          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.profile)}
          >
            <UserIcon />
            <AppText>{t("PROFILE_SETTINGS")}</AppText>
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.documents)}
          >
            <DocumentsIcon />
            <AppText>{t("DOCUMENTS")}</AppText>
            {requestedForms && requestedForms.length > 0 ? (
              <Badge badgeContent={requestedForms.length} />
            ) : null}
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.verification)}
          >
            <ZapIcon />
            <AppText>{t("VERIFICATION")}</AppText>
          </StyledMenuItem>

          {/* <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.activityLog)}
          >
            <LayersIcon />
            <AppText>{t("ACTIVITY_LOG")}</AppText>
          </StyledMenuItem>

          <StyledMenuItem
            onClick={() => navigateToProfile(routesPaths.referrals)}
          >
            <UserPlusIcon />
            <AppText>{t("REFERRALS")}</AppText>
          </StyledMenuItem> */}
          <MenuDivider />
          <StyledMenuItem onClick={sendEmail}>
            <MailIcon />
            <AppText>{t("CONTACT_SUPPORT")}</AppText>
          </StyledMenuItem>

          <StyledMenuItem onClick={fullLogOut}>
            <LogOutIcon />
            <AppText>{t("LOG_OUT")}</AppText>
          </StyledMenuItem>
        </StyledMenu>
      ) : (
        <StyledMenu
          anchorEl={isProfileMenuOpen}
          open={!!isProfileMenuOpen}
          onClose={handleCloseProfileMenu}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          <StyledMenuItem onClick={() => navigateToAuth(routesPaths.login)}>
            <SignInIcon />
            <AppText>{t("LOGIN")}</AppText>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => navigateToAuth(routesPaths.signUp)}>
            <SignUpIcon />
            <AppText>{t("SIGN_UP")}</AppText>
          </StyledMenuItem>
        </StyledMenu>
      )}

      {/* <StyledMenu
        anchorEl={isMetaMaskOpen}
        open={!!isMetaMaskOpen}
        onClose={handleCloseMetaMusk}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={() => null}>
          <MetaMaskIcon />
          <AppText>{t("DISCONNECT_METAMASK")}</AppText>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => null}>
          <PlusIcon />
          <AppText>{t("ADD_ANOTHER_WALLET")}</AppText>
        </StyledMenuItem>
      </StyledMenu> */}
    </UserMenuContainer>
  );
};

export default observer(UserMenu);
