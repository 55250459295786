import { FC } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/consts";
import authRoutes from "./authNav";
import { UserStore } from "stores";
// import type { LangType } from "helpers/types";

const Navigation: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = UserStore;
  return (
    <Nav>
      {isAuthenticated &&
        authRoutes.map(({ path, key, label, icon }) => {
          return (
            <NavItem key={key} to={path}>
              {icon && icon}
              {t(label)}
            </NavItem>
          );
        })}
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  margin-left: 1.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 4.4rem;
  max-height: 4.4rem;
  max-width: 100%;
`;

// interface NavItemInterface {
//   $lang?: LangType;
// }
const NavItem = styled(NavLink)`
  color: ${colors.gray_600};
  padding: 0.8rem;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  font-weight: 600;
 font-size: 1.4rem;
         > svg {
           min-width: 2rem;
           max-width: 2rem;
           margin-right: 0.8rem;
         }

  &.active {
    color: ${colors.primary_700};
    > svg path {
      stroke: ${colors.primary_700};
    }
  }

  > svg path {
      stroke: ${colors.gray_600};
      transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &:hover,
  &:focus {
    color: ${colors.primary_700};

    > svg path {
      stroke: ${colors.primary_700};
    }
  }
`;

export default observer(Navigation);
