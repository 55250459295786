const routesPaths = {
  dashboard: "/",
  login: "/login",
  forgotPassword: "/forgot-password",
  verifyEmail: "/verify-email/:verifyCode",
  emailConfirm: "/email-confirm",
  resetPassword: "/reset-password/:resetCode",
  signUp: "/sign-up",
  referral: "/r/:refferalCode",
  wallet: "/wallet",
  history: "/history",
  futures: "/futures",
  games: "/games",
  buyCrypto: "/buy-crypto",
  documentForm: "/document-form",
  adminRedirect: "/admin-redirect",

  earning: "/earning",
  liquidityPool: "/earning/liquidity-pool",
  copyTrading: "/earning/copy-trading",
  copyTradingStake: "/earning/copy-trading/stake",

  cryptoCards: "/crypto-cards",
  cryptoCardsBuy: "/crypto-cards/products",
  cryptoCardProduct: "/crypto-cards/products/",
  cryptoCardProductFull: "/crypto-cards/products/:productId",
  cryptoCardPhysicalProduct: "/crypto-cards/products/physical/",
  cryptoCardPhysicalProductFull: "/crypto-cards/products/physical/:productId",
  usersCryptoCard: "/crypto-cards/user/crypto/",
  usersCryptoCardFull: "/crypto-cards/user/crypto/:cardId",

  giftCards: "/gift-cards",
  giftCardsBuy: "/gift-cards/products",
  giftCardProduct: "/gift-cards/products/",
  giftCardProductFull: "/gift-cards/products/:productId",
  usersGiftCard: "/gift-cards/user/gift/",
  usersGiftCardFull: "/gift-cards/user/gift/:cardId",

  profile: "/profile",
  verification: "/profile/verification",
  activityLog: "/profile/activity-log",
  referrals: "/profile/referrals",
  documents: "/profile/documents",

  trade: "/trade",
  openOrders: "/trade/open-orders",
  closedOrders: "/trade/closed-orders",
};

export default routesPaths;
