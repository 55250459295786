import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Menu, MenuItem, Divider, List, Box } from "@mui/material";
import { colors } from "helpers/consts";

export const HeaderContentContainer = styled.div`
  margin: 0 auto;
  max-width: 121.6rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  * {
    font-family: "Inter", "Montserrat", sans-serif;
  }
`;

export const AdminLoginContainer = styled.div`
  width: 100%;
  border: solid 2px ${colors.error_500};
  border-radius: 1.2rem;
  padding: 1.2rem;
  background-color: ${colors.error_50};
  margin-top: 0.8rem;
  display: flex;
  justify-content: center;
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  padding: 0 1.2rem 1.2rem;

  @media (min-width: 36em) {
    padding: 0 1.6rem 1.2rem;
  }
  @media (min-width: 48em) {
    padding: 0 2rem 2.4rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 1.2rem;
  transition: padding 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  & > *:last-child {
    margin-left: 1rem;
  }

  @media (min-width: 48em) {
    padding-top: 1.6rem;
  }
`;

export const SeparateContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }
`;

export const LogoSeparateContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const Logo = styled.img.attrs({
  alt: "logo",
})`
  min-width: 5.2rem;
  max-width: 15rem;
`;

interface LogoLinkInterface {
  $isSidebar?: boolean;
}
export const LogoLink = styled(Link)<LogoLinkInterface>`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  justify-self: flex-start;
  margin-left: 0.4rem;

  ${({ $isSidebar }) =>
    $isSidebar &&
    `padding: 1.2rem 0 0.8rem 1.6rem;
     width: 100%;
     border-bottom: 1px solid ${colors.gray_100}; 
     margin-bottom: 2rem;`}
`;

interface UserMenuContainerInterface {
  $isInSidebar?: boolean;
}
export const UserMenuContainer = styled.div<UserMenuContainerInterface>`
  display: flex;
  align-items: center;
  ${({ $isInSidebar }) => $isInSidebar && `flex-direction: column;`}
  flex-wrap: wrap;

  & > *:not(:first-child) {
    margin: ${({ $isInSidebar }) =>
      $isInSidebar ? "0.8rem 0 0 0" : "0 0 0 0.8rem"};
  }

  .MuiBadge-badge {
    top: -0.5rem;
    right: -0.5rem;
  }

  @media (min-width: 38em) {
    flex-wrap: nowrap;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 1.4rem;
  padding: 1.5rem 1.6rem;
  font-weight: 500;

  & > *:not(.MuiTouchRipple-root):not(:first-child) {
    margin-left: 1rem;
  }

  & > span.MuiBadge-root {
    width: 1rem;
  }

  svg,
  img {
    max-width: 1.6rem;
    min-width: 1.6rem;
    max-height: 1.6rem;
    min-height: 1.6rem;
  }

  svg path {
    stroke: ${colors.gray_700};
  }
`;

export const StyledMenu = styled(Menu)`
  margin-top: 0.9rem;
  .MuiMenu-paper {
    border-radius: 0.8rem;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }
  .MuiMenu-list {
    min-width: 24rem;
    padding: 0;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1.2rem 1.6rem;
`;

export const MenuDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 0;
  }
`;

export const Image = styled.img.attrs({
  alt: "",
})`
  max-width: 2rem;
`;

export const SideBarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  & > svg {
    max-width: 2rem;
    min-width: 2rem;
    max-height: 2rem;
    min-height: 2rem;
    margin-left: 1.2rem;
  }

  & svg path {
    transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    stroke: ${colors.gray_600};
  }

  &:hover,
  &:focus {
    background-color: ${colors.gray_200};
    & svg path {
      stroke: ${colors.primary_600};
    }
    & p {
      color: ${colors.primary_600};
    }
  }
  &:active {
    background-color: ${colors.gray_100};
  }
  &.active {
    & svg path {
      stroke: ${colors.primary_600};
    }
    & p {
      color: ${colors.primary_600};
    }
  }
`;

export const SideBarLinkContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;

  & > p {
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  & > svg {
    margin-right: 1.2rem;
    max-width: 2.4rem;
    min-width: 2.4rem;
    max-height: 2.4rem;
    min-height: 2.4rem;
  }
`;

export const StyledNavList = styled(List)`
  display: flex;
  flex-direction: column;
  padding: 0 0 1.6rem 0;
  border-bottom: 1px solid ${colors.gray_100};

  & > *:not(:last-of-type) {
    margin-bottom: 0.4rem;
  }
`;

export const SidebarButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.4rem 1.6rem;
  margin-top: auto;

  & > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const StyledBox = styled(Box)`
  width: 310;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;
